<template>
  <div class="news">
    <div class="pressCenter">
      <div class="contant">
        <h3>NEWS</h3>
        <!-- <h4>{{ this.$t("news.newsCenter") }}</h4> -->
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="banner container" ref="banner">
      <el-carousel indicator-position="outside" :height="scrollHeight">
        <el-carousel-item v-for="item in 4" :key="item">
          <div class="left">
            <img
              src="../../assets/news/banner.png"
              alt=""
              width="100%"
              height="100%"
            />
          </div>
          <div class="right">
            <div class="time" style="text-align: right">2023<br />03</div>
            <hr class="time" />
            <h3 class="textOverflow">
              {{ getLanguage("bannerTitle") }}
            </h3>
            <p class="textOverflow2">
              {{ getLanguage("bannerContent") }}
            </p>
            <el-button type="text" @click="$router.push('/news/1')">{{ changeLanguage() }}</el-button>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="newsList.length == 0">
      <!-- 新闻 -->
      <div class="newsList container">
        <div class="newsItem" v-for="(item, index) in newsList" :key="index">
          <div class="left" v-if="item.imgSrc !== ''">
            <img :src="item.imgSrc" alt="" />
          </div>
          <div class="right">
            <p class="content">{{ item.content }}</p>
            <p class="time">
              时间：<span>{{ item.time }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :pager-count="5"
          :total="70"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else>
      <!-- <div class="notNewsList container">
        {{ this.$t("news.noData") }}
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 轮播图动态高度
      scrollHeight: "850px",
      newsList: [
        {
          content: "高仙机器人再登双榜，多维度指标名列前茅",
          time: "2023-02-20",
          imgSrc: "",
        },
        {
          content: "高仙参加“智慧物业与数字化转型暨联接未来思享会”",
          time: "2023-02-20",
          imgSrc: "",
        },
        {
          content:
            "《北京晚报》：16号线南段9站将开通！将连接4条地铁、1座火车站，ADE成标配",
          time: "2023-02-20",
          imgSrc: require("../../assets/news/NoPath.png"),
        },
        {
          content: "高仙机器人入选“2022中国品牌全球化100强”",
          time: "2023-02-20",
          imgSrc: require("../../assets/news/NoPath1.png"),
        },
      ],
    };
  },
  created() {},
  mounted() {
    // 获取浏览器可视窗口计算banner的高度
    this.scrollHeight = this.$refs.banner.clientWidth / (1200 / 560) + "px";
    // 浏览器视口改变，实时改变轮播图高度
    window.onresize = () => {
      return (() => {
        this.scrollHeight = this.$refs.banner.clientWidth / (1200 / 560) + "px";
      })();
    };
  },
  methods: {
    changeLanguage() {
      return this.$t("home.SeeMorel");
    },
    getLanguage(value) {
      return this.$t(`news.${value}`);
    },
  },
};
</script>

<style scoped lang='less'>
.news {
  .pressCenter {
    background: url("../../assets/news/字魂204，401769808.png") no-repeat center
      #001429;
    background-size: contain;
    position: relative;
    padding-bottom: 31.2%;
    .contant {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      h3 {
        font-size: 40px;
        font-weight: 700;
      }
      h4 {
        font-size: 50px;
        font-weight: 700;
      }
    }
  }
  .banner {
    margin: 50px auto !important;
    /deep/.el-carousel {
      // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
      .el-carousel__item:nth-child(2n) {
        background-color: #fafafa;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #fafafa;
      }
      .el-carousel__item {
        display: flex;
        .left {
          overflow: hidden;
          width: 60%;
          img {
            transition: all 0.4s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .right {
          padding: 34px 40px 0;
          width: 40%;
          h3 {
            margin: 40px 0 30px;
            font-size: 30px;
            line-height: 56px;
          }
          p {
            font-size: 16px;
            line-height: 32px;
          }
          .el-button {
            font-size: 14px;
            margin-top: 40px;
          }
          .textOverflow {
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 超出几行省略 */
          }
          .textOverflow2 {
            color: #666;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; /* 超出几行省略 */
          }
        }
      }
    }
  }
  .newsList {
    .newsItem {
      display: flex;
      border-bottom: 1px solid #e9e9e9;
      min-height: 90px;
      .left {
        margin: 20px 20px 20px 0;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        color: #3c4043;
        .content {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 24px;
          color: #0870d1;
        }
        .time {
          line-height: 16px;
        }
      }
    }
  }
  .notNewsList {
    margin-bottom: 40px !important;
    height: 80px;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    font-size: 20px;
    font-weight: 500;
    line-height: 80px;
    color: #aaa;
    text-align: center;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }
  // 当屏幕宽度小于860px时
  @media screen and (max-width: 860px) {
    .pressCenter {
      h3 {
        font-size: 20px !important;
      }
      h4 {
        font-size: 25px !important;
      }
    }
    .banner {
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 10px 10px 0 !important;
        .time {
          display: none;
          font-size: 12px !important;
        }
        h3 {
          margin: 10px 0 8px !important;
          font-size: 14px !important;
          font-weight: 700;
          line-height: 16px !important;
        }
        p {
          font-size: 12px !important;
          line-height: 14px !important;
        }
        .el-button {
          font-size: 14px;
          margin-top: 0 !important;
        }
      }
    }
    .newsList {
      padding: 15px;
    }
  }
}
</style>
