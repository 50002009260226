<template>
  <div>
    <div class="pressCenter">
      <img
        src="../../assets/mobileNews/蒙版组 58.png"
        alt=""
        width="100%"
      />
      <h3>NEWS</h3>
      <!-- <h4>{{ this.$t("news.newsCenter") }}</h4> -->
    </div>
    <div class="mobileContent">
      <!-- 轮播图 -->
      <div
        class="banner"
        ref="banner"
      >
        <el-carousel
          indicator-position="outside"
          height="89.6vw"
        >
          <el-carousel-item
            v-for="item in 1"
            :key="item"
          >
            <div class="top">
              <img
                ref="bannerImg"
                src="../../assets/mobileNews/蒙版组 57.png"
                alt=""
                width="100%"
              />
            </div>
            <div
              class="bottom"
              ref="bannerBottom"
            >
              <h3 class="textOverflow">
                {{ getLanguage("bannerTitle") }}
              </h3>
              <p class="textOverflow2">
                {{ getLanguage("bannerContent") }}
              </p>
              <el-button
                type="text"
                @click="$router.push('/news/1')"
              >{{
                changeLanguage()
              }}</el-button>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 详情样式 -->
      <div v-if="newsList.length !== 0">
        <!-- 新闻 -->
        <div class="newsList">
          <div
            class="newsItem"
            v-for="(item, index) in newsList"
            :key="index"
            @click="$router.push('/news/1')"
          >
            <div
              class="left"
              v-if="item.imgSrc !== ''"
            >
              <img
                :src="item.imgSrc"
                alt=""
              />
            </div>
            <div class="right">
              <p class="content">{{ item.content }}</p>
              <p class="time">
                时间：<span>{{ item.time }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 分页器
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="5"
            :total="70"
          >
          </el-pagination>
        </div> -->
      </div>

      <div v-else>
        <div class="notNewsList container">
          <!-- {{ this.$t("news.noData") }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 轮播图动态高度
      scrollHeight: "93.8667vw",
      newsList: [
        // {
        //   content: "高仙机器人再登双榜，多维度指标名列前茅",
        //   time: "2023-02-20",
        //   imgSrc: require("../../assets/news/NoPath1.png"),
        // },
        // {
        //   content: "高仙参加“智慧物业与数字化转型暨联接未来思享会”",
        //   time: "2023-02-20",
        //   imgSrc: require("../../assets/news/NoPath1.png"),
        // },
        // {
        //   content:
        //     "《北京晚报》：16号线南段9站将开通！将连接4条地铁、1座火车站，ADE成标配",
        //   time: "2023-02-20",
        //   imgSrc: require("../../assets/news/NoPath.png"),
        // },
        // {
        //   content: "高仙机器人入选“2022中国品牌全球化100强”",
        //   time: "2023-02-20",
        //   imgSrc: require("../../assets/news/NoPath1.png"),
        // },
      ],
    };
  },
  created() {},
  mounted() {
    // 获取浏览器标识字符串
    var ua = navigator.userAgent;
    if (ua.indexOf("iPad")) {
      this.scrollHeight = "86.8667vw";
    }
  },
  methods: {
    changeLanguage() {
      return this.$t("home.SeeMorel");
    },
    getLanguage(value) {
      return this.$t(`news.${value}`);
    },
  },
};
</script>

<style scoped lang="less">
.pressCenter {
  position: relative;

  img {
    vertical-align: bottom;
  }

  h3 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 5.3333vw;
    font-family: 'Meiryooo';
    font-weight: bold;
    line-height: 7.4667vw;
    color: #ffffff;
  }
}

.mobileContent {
  padding: 4.2667vw;
  .top {
    img {
      vertical-align: bottom;
    }
  }
  .banner {
    // height: 89.6vw;
    /deep/.el-carousel {
      .is-active {
        .el-carousel__button {
          background-color: #0064e6;
        }
      }
    }

    .bottom {
      background-color: #f8f8f8;
      padding: 2.6667vw;

      .textOverflow {
        font-size: 3.7333vw;
        line-height: 5.3333vw;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        /* 超出几行省略 */
      }

      .textOverflow2 {
        color: #666;
        margin: 2.6667vw 0;
        font-size: 3.2vw;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        /* 超出几行省略 */
      }
      /deep/ .el-button--text {
        font-size: 3.2vw;
        font-family: "Meiryooo";
        font-weight: 400;
        line-height: 4.5333vw;
        color: #0064e6;
      }
    }
  }
  .newsList {
    .newsItem {
      height: 24.5333vw;
      display: flex;
      align-items: center;
      .left {
        margin-right: 4.2667vw;
        img {
          width: 26.6667vw;
          vertical-align: bottom;
        }
      }
      .right {
        height: 100%;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content {
          font-size: 3.7333vw;
          font-family: "Meiryooo";
          font-weight: bold;
          line-height: 5.3333vw;
          color: #0870d1;
          margin-bottom: 1.0667vw;
        }
        .time {
          font-size: 3.2vw;
          font-family: "Meiryooo";
          font-weight: 400;
          line-height: 4.5333vw;
          color: #70757a;
        }
      }
    }
  }
}
</style>
